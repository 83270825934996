<template>
  <div class="search-results-listeners">
    <el-row :gutter="20">
      <el-col :span="8">
        <GrowthRateCard
          color="success"
          :value="aiParsedData.top10AverageGrowthRate"
          description="Growth rate higher percentage"
      /></el-col>
      <el-col :span="8">
        <GrowthRateCard
          color="primary"
          :value="aiParsedData.averageGrowthRate"
          description="Average growth rate"
      /></el-col>
      <el-col :span="8">
        <GrowthRateCard
          color="danger"
          :value="aiParsedData.bottom10AverageGrowthRate"
          description="Growth rate lower percentage"
      /></el-col>
    </el-row>
    <div class="card mb-5 mb-xl-10">
      <div class="card-header border-0 pt-5 align-items-center">
        <div>
          <h2 class="heading">Results</h2>
        </div>
        <div>
          <CardHeaderButtonAI
            @buttonClicked="exportData"
            :disabled="artistPage.length === 0"
            >Export</CardHeaderButtonAI
          >
          <a
            hidden
            :href="href"
            ref="exportButtonRef"
            :download="fileName + '.csv'"
            >Download</a
          >
        </div>
      </div>
      <div class="card-body">
        <el-table
          @sort-change="sortResults"
          row-key="id"
          :fit="true"
          table-layout="fixed"
          :data="artistPage"
        >
          <el-table-column label="" width="75">
            <template #default="scope">
              <div class="d-flex justify-content-center align-items-center">
                <el-avatar
                  @click="showArtistAnalytics(scope.row.uuid)"
                  class="cursor-pointer my-2"
                  :size="35"
                  :src="
                    scope.row.imageUrl
                      ? scope.row.imageUrl
                      : '/media/avatars/blank.png'
                  "
                ></el-avatar>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="name"
            label="Name"
            width="300"
          ></el-table-column>
          <el-table-column
            label="Monthly Listeners Now"
            sortable="custom"
            prop="totalSpotifyListeners"
          >
            <template #default="scope">
              <span>{{
                Number(scope.row.totalSpotifyListeners).toLocaleString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Year Ago"
            sortable="custom"
            prop="totalSpotifyListenersYearAgo"
          >
            <template #default="scope">
              <span>{{
                Number(scope.row.totalSpotifyListenersYearAgo).toLocaleString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Growth Rate"
            sortable="custom"
            prop="growthRate"
          >
            <template #default="scope">
              <div class="d-flex align-items-center justify-content-center">
                <span
                  :class="[
                    'svg-icon',
                    'svg-icon-3',
                    scope.row.growthRate > 0
                      ? 'svg-icon-success'
                      : 'svg-icon-danger',
                    'me-2',
                  ]"
                >
                  <inline-svg
                    :src="
                      scope.row.growthRate > 0
                        ? '/media/icons/duotune/arrows/arr066.svg'
                        : '/media/icons/duotune/arrows/arr065.svg'
                    "
                  />
                </span>
                <div>
                  {{ percentageFormatter(scope.row.growthRate) + "%" }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Action">
            <template #default="scope">
              <ArtistMenuComponent
                :artist="scope.row"
                :from-drawer="false"
                :buttons="[
                  'artistDetails',
                  'discover',
                  'watchlist',
                  'crawl',
                  'crm',
                ]"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-5"
          v-model:currentPage="localPagination.offset"
          v-model:page-size="localPagination.limit"
          :page-sizes="[5, 10, 20, 50]"
          :small="true"
          layout="total, sizes, prev, pager, next"
          :total="localPagination.total"
          @size-change="paginationChanged"
          @current-change="paginationChanged"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { paginate, sortArray } from "../helpers";
import GrowthRateCard from "../components/GrowthRateCard";
import CardHeaderButtonAI from "../components/CardHeaderButtonAI";
import ArtistMenuComponent from "../../artists/components/ArtistMenuComponent";

export default {
  name: "SearchResultListeners",
  components: { ArtistMenuComponent, CardHeaderButtonAI, GrowthRateCard },
  setup() {
    const store = useStore();
    const href = ref("");
    const artistPage = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const fileName = ref("");
    const sortedAllArtists = ref([]);
    const exportButtonRef = ref(null);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
    });

    const aiParsedData = computed(() => {
      return store.getters["AIBetaBench/getAIParsedData"];
    });
    const pagination = computed(() => {
      return store.getters["AIBetaBench/getSearchedListenersPagination"];
    });
    const selectedGenre = computed(() => {
      return store.getters["AIBetaBench/getFilterDataGenre"];
    });

    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };

    const openInDiscovery = (artistName) => {
      // store.dispatch("EventsModule/searchArtist", artistName);
      store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      window.open(`/discover?search=${artistName}`, "_blank");
    };

    watch(
      () => pagination.value,
      (value) => {
        localPagination.value = value;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    watch(
      () => aiParsedData.value,
      (results) => {
        sortedAllArtists.value = sortAllDataIfRequired(results.artists);
      },
      {
        deep: true,
        immediate: true,
      }
    );

    watch(
      () => sortedAllArtists.value,
      (results) => {
        artistPage.value = [];
        artistPage.value = paginate(
          results,
          localPagination.value.limit,
          localPagination.value.offset
        );
      },
      { immediate: true, deep: true }
    );

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      sortedAllArtists.value = sortAllDataIfRequired(
        JSON.parse(JSON.stringify(aiParsedData.value.artists))
      );
    };

    const paginationChanged = () => {
      store.commit(
        "AIBetaBench/SET_SEARCHED_LISTENERS_PAGINATION",
        localPagination.value
      );
      artistPage.value = paginate(
        sortedAllArtists.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };

    const exportData = async () => {
      const filteredArtists = aiParsedData.value.artists.map((item) => {
        return {
          Name: item.name,
          "Year ago": item.totalSpotifyListenersYearAgo,
          Listeners: item.totalSpotifyListeners,
          "Growth rate": Number(item.growthRate).toFixed(2) + "%",
        };
      });
      const keys = Object.keys(filteredArtists[0]);
      const commaSeparatedString = [
        keys.join(","),
        filteredArtists
          .map((row) => keys.map((key) => row[key]).join(","))
          .join("\n"),
      ].join("\n");
      const csvBlob = new Blob([commaSeparatedString]);
      href.value = URL.createObjectURL(csvBlob);
      fileName.value = selectedGenre.value + " (Artists)";
      setTimeout(() => {
        exportButtonRef.value.click();
      }, 100);
    };

    const percentageFormatter = (percentage) => {
      const parsed = Number(percentage).toFixed(2);
      if (percentage < 0) {
        return parsed * -1;
      }
      return parsed;
    };

    return {
      artistPage,
      localPagination,
      aiParsedData,
      exportButtonRef,
      href,
      fileName,
      //Methods
      sortResults,
      paginationChanged,
      percentageFormatter,
      openInDiscovery,
      exportData,
    };
  },

  methods: {
    showArtistAnalytics(uuid) {
      this.openArtistProfileDrawer(uuid);
    },
  },
};
</script>

<style scoped></style>
