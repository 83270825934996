<template>
  <div class="growth-rate-card">
    <div class="card text-center h-100 mb-5">
      <div v-if="value" class="card-body">
        <div class="d-flex align-items-center justify-content-center">
          <span
            :class="[
              'svg-icon',
              'svg-icon-3',
              value > 0 ? 'svg-icon-success' : 'svg-icon-danger',
              'me-2',
            ]"
          >
            <inline-svg
              :src="
                value > 0
                  ? '/media/icons/duotune/arrows/arr066.svg'
                  : '/media/icons/duotune/arrows/arr065.svg'
              "
            />
          </span>
          <h3 :class="`text-${color}`">
            {{ percentageFormatter(value) + "%" }}
          </h3>
        </div>
        <h6 class="subheading">
          {{ description }}
        </h6>
      </div>
      <div v-else class="card-body">
        <h3 class="heading">No data available</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GrowthRateCard",
  props: {
    color: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup() {
    const percentageFormatter = (percentage) => {
      return Number(percentage).toFixed(2);
    };
    return { percentageFormatter };
  },
};
</script>

<style scoped></style>
